import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { ImageIcon } from "../../common/ImageIcon";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { Link } from "react-router-dom";
import { useState } from "react";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
  FooterContent,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        style={{ marginLeft: "10px" }}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              {/* <Large to="/">{t("Tell us everything")}</Large> */}
              <Para>{t(`您有任何问题请随时联系我们 😊`)}</Para>
              <a href="mailto:contact@ffaninfo.com">
                <p style={{ fontSize: 20, textDecoration: "underline" }}>
                  {t(`contact@ffaninfo.com`)}
                </p>
              </a>
              <Col>
                <Language>{t("Address")}</Language>
                <Para>上海市长宁区</Para>
                <Para style={{ width: "100%" }}>
                  天山西路568号统一企业广场B区六楼
                </Para>
              </Col>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} style={{ paddingLeft: "50px" }}>
              <Title>{t("Solutions")}</Title>
              <a
                style={{
                  display: "block",
                  fontSize: 16,
                  color: "#000",
                  marginBottom: "10px",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.color = "#1890ff";
                  e.currentTarget.style.textDecoration = "wavy underline";
                  e.currentTarget.style.textUnderlinePosition = "under";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.color = "#000";
                  e.currentTarget.style.textDecoration = "none";
                }}
                href="/#solution"
              >
                {" "}
                {t("IT资源平台")}
              </a>
              <a
                style={{
                  display: "block",
                  fontSize: 16,
                  color: "#000",
                  marginBottom: "10px",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.color = "#1890ff";
                  e.currentTarget.style.textDecoration = "wavy underline";
                  e.currentTarget.style.textUnderlinePosition = "under";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.color = "#000";
                  e.currentTarget.style.textDecoration = "none";
                }}
                href="/#solution"
              >
                {" "}
                {t("信息安全")}
              </a>
              <a
                style={{
                  display: "block",
                  fontSize: 16,
                  color: "#000",
                  marginBottom: "10px",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.color = "#1890ff";
                  e.currentTarget.style.textDecoration = "wavy underline";
                  e.currentTarget.style.textUnderlinePosition = "under";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.color = "#000";
                  e.currentTarget.style.textDecoration = "none";
                }}
                href="/#solution"
              >
                {" "}
                {t("IT运营服务")}
              </a>
              <a
                style={{
                  display: "block",
                  fontSize: 16,
                  color: "#000",
                  marginBottom: "10px",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.color = "#1890ff";
                  e.currentTarget.style.textDecoration = "wavy underline";
                  e.currentTarget.style.textUnderlinePosition = "under";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.color = "#000";
                  e.currentTarget.style.textDecoration = "none";
                }}
                href="/#solution"
              >
                {" "}
                {t("数字化办公")}
              </a>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} style={{ paddingLeft: "50px" }}>
              <Title>{t("Company")}</Title>
              <a
                style={{
                  display: "block",
                  fontSize: 16,
                  color: "#000",
                  marginBottom: "10px",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.color = "#1890ff";
                  e.currentTarget.style.textDecoration = "wavy underline";
                  e.currentTarget.style.textUnderlinePosition = "under";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.color = "#000";
                  e.currentTarget.style.textDecoration = "none";
                }}
                href="/#about-us"
              >
                {" "}
                {t("公司介绍")}
              </a>
              <a
                style={{
                  display: "block",
                  fontSize: 16,
                  color: "#000",
                  marginBottom: "10px",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.color = "#1890ff";
                  e.currentTarget.style.textDecoration = "wavy underline";
                  e.currentTarget.style.textUnderlinePosition = "under";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.color = "#000";
                  e.currentTarget.style.textDecoration = "none";
                }}
                onClick={() =>
                  window.open(
                    "https://www.zhipin.com/gongsi/667242035d4cb1f11n1y0tW8FVE~.html?ka=search_rcmd_company_name_667242035d4cb1f11n1y0tW8FVE~_custompage"
                  )
                }
                // href="/#"
              >
                {" "}
                {t("加入我们")}
              </a>
              {/* </div> */}
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} style={{ paddingLeft: "50px" }}>
              <Label htmlFor="select-lang">{t("特推产品")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch
                  onClick={() => window.open("https://copilot.ffaninfo.com")}
                >
                  <SvgIcon
                    src="copilot.svg"
                    aria-label="copilot"
                    width="40px"
                    height="35px"
                  />
                </LanguageSwitch>
                <LanguageSwitch
                  onClick={() => window.open("https://www.donbe.cc")}
                >
                  <ImageIcon
                    src="donbe.png"
                    aria-label="donbe"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>

            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left="true" onClick={() => scrollTo("solution")}>
               
              </Large>
              <Large left="true" to="/">
                
              </Large>
            </Col> */}
          </Row>
          {/* <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Para>上海市长宁区</Para>
              <Para>天山西路568号统一企业广场B区六楼</Para>
            </Col>
          </Row> */}
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <ImageIcon
                  src="logo-ffan.png"
                  aria-label="homepage"
                  width="101px"
                  height="70px"
                />
              </LogoContainer>
            </NavLink>
            <div className="text-gray-400 text-sm mr-4">
              {" "}
              Copyright &copy; 非帆数码科技 ffaninfo.com |{" "}
              <a
                className="text-gray-400 text-sm mr-4"
                href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
              >
                沪ICP备2023011729号-1
              </a>
            </div>
            <div>
              <FooterContainer>
                <SocialLink
                  href="https://github.com/donbe-cc"
                  src="github.svg"
                />
                <SocialLink
                  href="https://www.zhihu.com/org/dong-bi-ke-ji"
                  src="zhihu.svg"
                />
                <SocialLink
                  href="https://copilot.ffaninfo.com"
                  src="copilot.svg"
                />
              </FooterContainer>
            </div>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
